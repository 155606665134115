import { useTranslation } from "react-i18next";
import "../../assets/styles/general.scoped.css";

import GeneralTeam from "../../assets/images/general-team.svg";
import SearchIcon from "../../assets/images/search-icon.svg";

function GeneralTopBlock({ search, setSearch }) {
  const { t } = useTranslation();

  const handleChange = (e) => setSearch(e.target.value);

  return (
    <div className="general_top_block">
      <div className="general_title_block">
        <img src={GeneralTeam} alt="general-team" />
        <p className="general_title">{t("generalteam:TOP_TITLE")}</p>
      </div>
    </div>
  );
}
export default GeneralTopBlock;
